function AboutPage() {
  const { aboutPage } = useSiteMetadata()
  const { name, title, paragraphs } = useAboutData()
  const { lesley } = useContactData()

  return (
    <Base>
      <Metadata page={aboutPage} />

      <Header>
        <Name>{name}</Name>
        <Title>{title}</Title>
      </Header>

      <main
        id="main-content"
        tabIndex="-1"
        css="padding-bottom: var(--s10);text-align: center"
      >
        <Article>
          {paragraphs.map(paragraph => (
            <Paragraph
              key={paragraph}
              dangerouslySetInnerHTML={{ __html: paragraph }}
            />
          ))}
        </Article>

        <Link href={lesley.imdb.href} css="font-size: 0.96rem;">
          Learn more on IMDB
        </Link>
      </main>
    </Base>
  )
}

///////////////////////////////////////////////////////////////////////////////////

const Header = styled.header`
  padding-top: var(--s8);
  text-align: center;
`

const Name = styled.h1`
  padding-top: var(--s5);
  font-size: var(--f6);
  font-weight: 400;
`

const Title = styled.p`
  padding-top: var(--s1);
`

const Article = styled.article`
  ${copy}
  margin-left: auto;
  margin-right: auto;
  padding-top: var(--s6);
  padding-bottom: var(--s7);
  text-align: left;
  font-size: 0.96rem;
`

const Paragraph = styled.p`
  padding-top: var(--s4);
`

///////////////////////////////////////////////////////////////////////////////////

import React from 'react'
import styled from 'styled-components'

import Base from '../ui/Base'
import Metadata from '../ui/Metadata'
import { Link } from '../ui/elements'
import useSiteMetadata from '../data/useSiteMetadata'
import useAboutData from '../data/useAboutData'
import useContactData from '../data/useContactData'
import { copy } from '../styles'

export default AboutPage
