// See: https://www.gatsbyjs.org/blog/2019-02-20-introducing-use-static-query/

function useAboutData() {
  const { aboutYaml } = useStaticQuery(
    graphql`
      query {
        aboutYaml {
          name
          title
          paragraphs
        }
      }
    `
  )

  return aboutYaml
}

///////////////////////////////////////////////////////////////////////////////////

import { useStaticQuery, graphql } from 'gatsby'

export default useAboutData

/*

import useAboutData from '../data/useAboutData'

const { title, description, url } = useAboutPageMetadata()
const { name, title, paragraphs } = useAboutData()

*/
